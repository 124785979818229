

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { langs } from './langs';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

const rootReducer = combineReducers({
  form: formReducer
});

const store = createStore(rootReducer)



function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['ba'];
}


Object.get = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {

    /*if (!localStorage.translate){
      localStorage.translate = JSON.stringify({
        'ba': {
 
        },
        'sr': {
 
        },
        'en': {
 
        }
      });
    }
 
    let obj = JSON.parse(localStorage.translate);
    obj.en[this] = this;
    obj.sr[this] = this;
 
    localStorage.translate = JSON.stringify(obj);
    
    return this;*/

    if (langs[lang] && langs[lang][this])
      return langs[lang][this];
    else return this;
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);


    let lang = 'ba';
    if (typeof window !== 'undefined') {

      window.googleMapsCallback = this.googleMapsCallback;

      if (props.location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      } else if (props.location.pathname.indexOf('/sr') !== -1) {
        lang = 'sr';
      }else if (props.location.pathname.indexOf('/de') !== -1) {
        lang = 'de';
      }


    } else {
      lang = this.props.lang;
    }
    this.state = {
      _googleMapsLoaded: false,
      lang: lang,
      lightMode: 0,
      services: [],
      latestNews: [],
      ...props.appInitialData

    };

  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }





  translate(text) {
    return text;
  }

  render() {
    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title && this.state.metaTags.title[this.state.lang],
        description: this.state.metaTags.description && this.state.metaTags.description[this.state.lang],
        //canonical: 'http://example.com/path/to/page',
        meta: {
          charset: 'utf-8',
          name: {
            'og:url': this.state.metaTags['og:url'] && this.state.metaTags['og:url'][this.state.lang],
            'og:title': this.state.metaTags['og:title'] && this.state.metaTags['og:title'][this.state.lang],
            'og:image': this.state.metaTags['og:image'] && this.state.metaTags['og:image'][this.state.lang],
            'og:description': this.state.metaTags['og:description'] && this.state.metaTags['og:description'][this.state.lang],
            'og:type': this.state.metaTags['og:type'] && this.state.metaTags['og:type'][this.state.lang],
          }
        }
      };
    }

    return (
      <Provider store={store}>
        {this.state.metaTags ? <DocumentMeta {...meta} /> : null}
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          allowCookies={() => {
            localStorage.allowCookies = true;
            this.setState({
              cookies: true
            });
          }}
        />
      </Provider>

    );

  }



  checkCountry = () => {
    if (typeof window !== 'undefined') {
      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          if (data && data.country_code && data.country_code.toLowerCase() === 'de') {
            this.setLang('de');
          }
         
        });
    }

  }



  componentDidMount() {

    try {
      this.checkCountry();
    }catch(err) {
      console.log(err);
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

//     <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-NR1YCVT941"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-NR1YCVT941');
// </script>

    // ReactGA.initialize('UA-75168242-1');
    // ReactGA.pageview(this.props.location.pathname);
    ReactGA.initialize('G-NR1YCVT941');
    ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });


    this.props.history.listen((location, action) => {
      let lang = 'ba';
      if (location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      } else if (location.pathname.indexOf('/sr') !== -1) {
        lang = 'sr';
      }else if (location.pathname.indexOf('/de') !== -1) {
        lang = 'de';
      }


      if (this.state.lang != lang) {
        this.setState({ lang: lang }, () => {
          fetch('https://api.novamedia.agency/seo/' + this.state.lang, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: location.pathname })
          }).then((res) => res.json()).then((result) => {

            this.setState({
              metaTags: result
            })
          });

        });
      } else {
        fetch('https://api.novamedia.agency/seo/' + this.state.lang, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url: location.pathname })
        }).then((res) => res.json()).then((result) => {

          this.setState({
            metaTags: result
          })
        });

      }


      ReactGA.pageview(location.pathname + location.search);

      // location is an object like window.location

      //console.log(action, location.pathname, location.state)
    });




    fetch('https://api.novamedia.agency/services', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => res.json()).then((services) => {
      console.log(services);
      this.setState({
        services
      })
    })

    fetch('https://api.novamedia.agency/blog/latest', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => res.json()).then((latestNews) => {
      console.log(latestNews);
      this.setState({
        latestNews
      })
    })


    fetch('https://www.instagram.com/novamedia.agency/', {
      headers: { 'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.80 Safari/537.36' }
    }).then((res) => res.text()).then((source) => {
      try {
        var json = JSON.parse(source.split('window._sharedData =')[1].split(';</script>')[0]);
        let edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges;
        console.log(edges)
        let instagramPhotos = edges.map((item) => {
          return {
            preview: item.node.thumbnail_resources[0].src,
            link: 'https://instagram.com/p/' + item.node.shortcode
          }
        });
        this.setState({
          instagramPhotos: instagramPhotos.slice(0, 6)
        })
      } catch (e) {

      }



    })





  }



  googleMapsCallback() {
    console.log("true");
    this.setState({ _googleMapsLoaded: true });
  }

}

export default withRouter(App);
