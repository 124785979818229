import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



import downChevron from '../assets/svg/down-chevron.svg';

import Map from '../components/map';
import ContactForm from '../components/forms/contactForm';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

    }

    submit(data) {
        this.setState({
            formLoading: true
        }, () => {

            fetch('https://api.novamedia.agency/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(
                    data
                )
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    formLoading: null,
                    formDone: true
                })
            })
        })



        console.log(data);
    }


    render() {

        return (
            <div className="contact-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <h1>{'Kontaktirajte nas'.translate(this.props.lang)}</h1>
                                <p>{'Ispunite donji obrazac i uskoro ćemo biti u kontaktu.'.translate(this.props.lang)}</p>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>
                        </Row>
                    </Container>
                </div>

                <section className="contact-page-section">
                    <Container>
                        <Row>
                            <Col lg="5">
                                <div className="item">
                                    <h3>{'Adresa'.translate(this.props.lang)}</h3>
                                    <h6>NOVA media</h6>
                                    {this.props.lang === 'de' ?
                                        <p>
                                            Alemannenstr. 23<br />
                                            89522 Heidenheim an der Brenz</p>
                                        :
                                        <p>Mese Selimovica 23b/21<br />
                                            76 300 Bijeljina<br />
                                            Bosna i Hercegovina</p>
                                    }
                                </div>
                                <div className="item">
                                    <h3>{'Kontakt'.translate(this.props.lang)}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: 'Imate projekat o kojem želite da<br />razgovarate? Kontaktirajte nas.'.translate(this.props.lang) }}></p>
                                    {this.props.lang === 'de' ? <a href="mailto:info@novamedia.tech">info@novamedia.tech</a>
                                        : <a href="mailto://">info@novamedia.agency</a>}
                                </div>
                                <div className="item">
                                    <h3>{'Pozovite nas'.translate(this.props.lang)}</h3>
                                    {this.props.lang === 'de' ?

                                        <p>
                                            Tel.: +49 (0) 7321 4889 490<br />
                                            Mob: +49 (0) 1520 271 8899

                                        </p>
                                        :
                                        <p>+387 66 220 336<br />

                                            +387 65 498 720</p>
                                    }
                                </div>
                            </Col>
                            <Col lg="6">
                                <h3>{'Kontaktirajte nas'.translate(this.props.lang)}</h3>
                                {this.state.formDone ?
                                    <div className="form-done">
                                        <div className="check">

                                        </div>
                                        <p>{'Vaša poruka je poslata, očekujte odgovor ubrzo.'.translate(this.props.lang)}</p>

                                    </div>
                                    :
                                    <ContactForm lang={this.props.lang} onSubmit={this.submit} loading={this.state.formLoading} />

                                }
                            </Col>
                            <Col lg="12">
                                <Map {...this.props} />
                            </Col>
                        </Row>
                    </Container>
                </section>



            </div>
        );
    }
}

export default Page(ContactPage);
