import { matchPath } from 'react-router-dom';

const allLinks = [

    {
        path: '/',
        orig: '/',
        lang: 'ba'
    },
    {
        path: '/usluge',
        orig: '/usluge',
        lang: 'ba'
    },
    {
        path: '/usluge/:alias',
        orig: '/usluge/:alias',
        lang: 'ba'
    },
    {
        path: '/reference',
        orig: '/reference',
        lang: 'ba'
    },
    {
        path: '/reference/:alias',
        orig: '/reference/:alias',
        lang: 'ba'
    },
    {
        path: '/blog',
        orig: '/blog',
        lang: 'ba'
    },
    {
        path: '/blog/:alias',
        orig: '/blog/:alias',
        lang: 'ba'
    },
    {
        path: '/o-nama',
        orig: '/o-nama',
        lang: 'ba'
    },
    {
        path: '/kontakt',
        orig: '/kontakt',
        lang: 'ba'
    },
    {
        path: '/privacy-policy',
        orig: '/privacy-policy',
        lang: 'ba'
    },
    {
        path: '/terms-and-conditions',
        orig: '/terms-and-conditions',
        lang: 'ba'
    },
    {
        path: '/impressum',
        orig: '/impressum',
        lang: 'ba'
    },

    {
        path: '/sr',
        orig: '/',
        lang: 'sr'
    },
    {
        path: '/sr/usluge',
        orig: '/usluge',
        lang: 'sr'
    },
    {
        path: '/sr/usluge/:alias',
        orig: '/usluge/:alias',
        lang: 'sr'
    },
    {
        path: '/sr/reference',
        orig: '/reference',
        lang: 'sr'
    },
    {
        path: '/sr/reference/:alias',
        orig: '/reference/:alias',
        lang: 'sr'
    },
    {
        path: '/sr/blog',
        orig: '/blog',
        lang: 'sr'
    },
    {
        path: '/sr/blog/:alias',
        orig: '/blog/:alias',
        lang: 'sr'
    },
    {
        path: '/sr/o-nama',
        orig: '/o-nama',
        lang: 'sr'
    },
    {
        path: '/sr/kontakt',
        orig: '/kontakt',
        lang: 'sr'
    },
    {
        path: '/sr/privacy-policy',
        orig: '/privacy-policy',
        lang: 'sr'
    },
    {
        path: '/sr/terms-and-conditions',
        orig: '/terms-and-conditions',
        lang: 'sr'
    },
    {
        path: '/sr/impressum',
        orig: '/impressum',
        lang: 'sr'
    },

    {
        path: '/en',
        orig: '/',
        lang: 'en'
    },
    {
        path: '/en/services',
        orig: '/usluge',
        lang: 'en'
    },
    {
        path: '/en/services/:alias',
        orig: '/usluge/:alias',
        lang: 'en'
    },
    {
        path: '/en/portfolio',
        orig: '/reference',
        lang: 'en'
    },
    {
        path: '/en/portfolio/:alias',
        orig: '/reference/:alias',
        lang: 'en'
    },
    {
        path: '/en/blog',
        orig: '/blog',
        lang: 'en'
    },
    {
        path: '/en/blog/:alias',
        orig: '/blog/:alias',
        lang: 'en'
    },
    {
        path: '/en/about-us',
        orig: '/o-nama',
        lang: 'en'
    },
    {
        path: '/en/contact',
        orig: '/kontakt',
        lang: 'en'
    },
    {
        path: '/en/privacy-policy',
        orig: '/privacy-policy',
        lang: 'en'
    },
    {
        path: '/en/terms-and-conditions',
        orig: '/terms-and-conditions',
        lang: 'en'
    },
    {
        path: '/en/impressum',
        orig: '/impressum',
        lang: 'en'
    },

    {
        path: '/de',
        orig: '/',
        lang: 'de'
    },
    {
        path: '/de/services',
        orig: '/usluge',
        lang: 'de'
    },
    {
        path: '/de/services/:alias',
        orig: '/usluge/:alias',
        lang: 'de'
    },
    {
        path: '/de/portfolio',
        orig: '/reference',
        lang: 'de'
    },
    {
        path: '/de/portfolio/:alias',
        orig: '/reference/:alias',
        lang: 'de'
    },
    {
        path: '/de/blog',
        orig: '/blog',
        lang: 'de'
    },
    {
        path: '/de/blog/:alias',
        orig: '/blog/:alias',
        lang: 'de'
    },
    {
        path: '/de/uber-uns',
        orig: '/o-nama',
        lang: 'de'
    },
    {
        path: '/de/kontakt',
        orig: '/kontakt',
        lang: 'de'
    },
    {
        path: '/de/datenschutzerklarung',
        orig: '/privacy-policy',
        lang: 'de'
    },
    {
        path: '/de/arbeitsbedingungen',
        orig: '/terms-and-conditions',
        lang: 'de'
    },
    {
        path: '/de/impressum',
        orig: '/impressum',
        lang: 'de'
    },

]




export default function (link){

    let newLink;
    allLinks.some(route => {

        const match = matchPath(link, route.path);
        if (match && match.isExact) {
            newLink = link.replace(route.path.split(':')[0], route.orig.split(':')[0]);
        }
        return match && match.isExact;
    });

    return newLink;
}