import React, { Component } from 'react';
import Link from './link';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/svg/logo.svg';
import rightChevron from '../assets/svg/right-chevron.svg';
import facebook from '../assets/svg/facebook.svg';
import instagram from '../assets/svg/instagram.svg';
import linkedin from '../assets/svg/linkedin.svg';
import twitter from '../assets/svg/twitter.svg';

import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (
            <footer>
                <Container fluid>
                    <Row>
                        <Col lg="3" xs="12" className="logo">
                            <Isvg src={logo} />
                            <p>Copyright © 2020. NOVA media</p>
                        </Col>
                        <Col lg="3" xs="6" sm="4">
                            <h6>{'Usluge'.translate(this.props.lang)}</h6>
                            <ul>


                                {
                                    this.props.services && this.props.services.map((item, idx) => {
                                        return (
                                            <li><Link lang={this.props.lang} to={`/usluge/${Object.translate(item, 'info.title', this.props.lang).generateAlias()}`}>{Object.translate(item, 'info.title', this.props.lang)}</Link> </li>

                                        )
                                    })
                                }

                            </ul>
                        </Col>
                        <Col lg="3" xs="6" sm="4">
                            <h6>{'Lokacija'.translate(this.props.lang)}</h6>
                            {this.props.lang === 'de' ?
                            <p>
                                    Alemannenstr. 23<br/>
    89522 Heidenheim an der Brenz<br/><br />
    Tel.: +49 (0) 7321 4889 490<br/>
    Mob: +49 (0) 1520 271 8899<br/>
    <a href="mailto:info@novamedia.tech">info@novamedia.tech</a><br/>


                            </p>
                            :<p>Meše Selimovića 23b/21<br />
                                76300 Bosna and Herzegovina<br /><br />

                                +387 66 220 336</p>
    }
                        </Col>
                        <Col lg="3" xs="12" sm="4">
                            <h6>{'Instagram feed'.translate(this.props.lang)}</h6>
                            <Row className="instagram-feed">
                                {
                                    this.props.instagramPhotos && this.props.instagramPhotos.map((item, idx) => {
                                        return (
                                            <Col lg="4" xs="4">
                                                <a href={item.link} target="_blank">
                                                    <img src={item.preview} />
                                                </a>
                                            </Col>
                                        )
                                    } )
                                }
                            </Row>
                        </Col>
                        <Col lg="12" className="navigation">
                            <ul>
                                <li><Link lang={this.props.lang} to='/blog'>{'Blog'.translate(this.props.lang)}</Link> </li>
                                <li><Link lang={this.props.lang} to='/terms-and-conditions'>{'Uslovi korištenja'.translate(this.props.lang)}</Link> </li>
                                {this.props.lang === 'de' ? <li><Link lang={this.props.lang} to='/impressum'>{'Impressum'.translate(this.props.lang)}</Link> </li> : null}
                                <li><Link lang={this.props.lang} to='/privacy-policy'>{'Politika privatnosti'.translate(this.props.lang)}</Link> </li>
                                <li><Link lang={this.props.lang} to='/kontakt'>{'Kontaktirajte nas'.translate(this.props.lang)}</Link> </li>

                            </ul>
                            <div className="social">
                                <a href={'https://www.facebook.com/www.novamedia.agency'.translate(this.props.lang)} target="_blank"><Isvg src={facebook} /></a>
                                <a href={'https://www.instagram.com/novamedia.agency/'.translate(this.props.lang)} target="_blank"><Isvg src={instagram} /></a>
                                <a href={'https://www.linkedin.com/company/novamediabn/'.translate(this.props.lang)} target="_blank"><Isvg src={linkedin} /></a>
                                <a href={'https://twitter.com/novamedia01'.translate(this.props.lang)} target="_blank"><Isvg src={twitter} /></a>

                            </div>
                        </Col>

                    </Row>
                </Container>

            </footer>
        );
    }
}

export default Footer;
