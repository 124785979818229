import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import Technologies from '../containers/sections/technologies';
import Contact from '../containers/sections/contact';
import Services from '../containers/sections/services';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import downChevron from '../assets/svg/down-chevron.svg';


const html = `
    <p><strong>NOVA Media</strong><br>
    Dario Stevanovic<br>
    Alemannenstr. 23<br>
    89522 Heidenheim an der Brenz<br>
    Tel.: +49 (0) 7321 4889 490<br>
    Mob: +49 (0) 1520 271 8899<br>
    <a href="mailto:info@novamedia.tech">info@novamedia.tech</a><br>
    <a href="https://www.novamedia.agency/de">www.novamedia.agency/de</a><br>
    Geschäftsführer: Dario Stevanovic<br>
    Amtsgericht Ulm-HRB 2269<br>
    USt.-IdNr.: DE 357 829 228</p>

    <h2>Haftung für Inhalte</h2>
    <p>Als Dienstanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter jedoch nicht verpflichtet übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

    <h2>Haftung für Links</h2>
    <p>Unser Angebot enthält Links zu externen Webseiten Dritter auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverlet






`

class ImprintPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') {window.scrollTo(0, 0);}


        for(let i=0;i<this.props.loadData.length;i++){
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


    }



    render() {

        return (
            <div className="about-us-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>

                            <Col lg="12" className="content">
                                <h1>Impressum</h1>
                            </Col>
                            <button className="scroll-down"><Isvg src={downChevron} /></button>
                        </Row>
                    </Container>
                </div>

                <section className="about-us-content">
                    <Container>
                        <Row>
                            <Col lg="12" dangerouslySetInnerHTML={{ __html: html }}>

                            </Col>

                        </Row>
                    </Container>


                </section>


                <Contact lang={this.props.lang}/>

            </div>
        );
    }
}

export default Page(ImprintPage);