import React, { Component } from 'react';
import Link from '../../components/link';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';



import rightChevron from '../../assets/svg/right-chevron.svg';

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (

            <section className="contact-section">
                <Container>
                    <Row>
                        <Col lg="12">
                            <h2 dangerouslySetInnerHTML={{ __html: 'Imate projekat o kojem<br/>želite da razgovarate?'.translate(this.props.lang) }}></h2>
                            <Link lang={this.props.lang} to='/kontakt'><button className="button">{'Kontaktirajte nas'.translate(this.props.lang)} <Isvg src={rightChevron} /></button></Link>

                            <p>
                                {this.props.lang === 'de' ? <a href="mailto:info@novamedia.tech">info@novamedia.tech</a> : <a href="#">info@novamedia.agency</a>}
                                <br /><br />
                                {'ili'.translate(this.props.lang)}
                                <br /><br />
                                {
                                    this.props.lang === 'de' ?
                                        <>
                                            Tel.: +49 (0) 7321 4889 490<br />
                                            Mob: +49 (0) 1520 271 8899

                                        </>
                                        :

                                        <a href="#">+387 66 220 336</a>

                                }
                            </p>

                        </Col>

                    </Row>
                </Container>
                <div className="spacer"></div>
            </section>
        );
    }
}

export default Contact;
